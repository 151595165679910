import { useEffect, useState } from 'react'

import { Auth } from './Auth'
import { People } from './Dashboard'

function App() {
  const [key, setKey] = useState<string | null>(null)

  useEffect(() => {
    setKey(localStorage.getItem('supabaseKey'))
  }, [])

  return <>{key ? <People /> : <Auth />}</>
}

export default App
