import { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export function Button({
  active = false,
  children,
  className,
  ...props
}: { active?: boolean } & HTMLAttributes<HTMLElement>) {
  return (
    <button
      type="button"
      className={twMerge(
        'relative inline-flex items-center rounded-md px-2 py-1.5 ring-1 ring-inset ring-gray-300 hover:bg-gray-800',
        active && 'bg-purple-500 hover:bg-purple-400',
        className,
      )}
      {...props}
    >
      {children}
    </button>
  )
}
