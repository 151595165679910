export function Search({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string
  setSearchQuery: (query: string) => void
}) {
  return (
    <input
      type="text"
      name="search"
      id="search"
      placeholder="Search"
      value={searchQuery}
      className="block w-[30%] rounded-md border-0 bg-gray-700 py-1.5 pr-14 text-sm shadow-sm placeholder:text-gray-400 focus:ring-gray-900"
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  )
}
