import { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from './Button'

export function AddUser({
  className,
  ...props
}: { active?: boolean } & HTMLAttributes<HTMLElement>) {
  return (
    <Button
      className={twMerge('bg-purple-700 text-white hover:bg-purple-500', className)}
      {...props}
    >
      Add User
    </Button>
  )
}
