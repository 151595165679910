import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { Button as HeaderButton } from './Button'
import { FilterType } from './types'
import { Button } from '../Button'

export function FilterMenu({
  filters,
  handleSetFitler,
  handleClearFilters,
}: {
  filters: FilterType
  handleSetFitler: (key: 'source' | 'priority', value: string) => void
  handleClearFilters: () => void
}) {
  const sources = [
    { id: 'LinkedIn', name: 'LinkedIn' },
    { id: 'Tally', name: 'Tally' },
    { id: 'Mailchimp', name: 'Mailchimp' },
    { id: 'Sales Navigator', name: 'Sales Navigator' },
  ]

  const priorities = [
    { id: '1', name: 'Priority 1' },
    { id: '2', name: 'Priority 2' },
    { id: '3', name: 'Priority 3' },
    { id: '4', name: 'Priority 4' },
    { id: '5', name: 'Priority 5' },
  ]

  const filtersCount = filters.source.length + filters.priority.length

  return (
    <div className="inline-flex rounded-md shadow-sm">
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button>
          <HeaderButton>
            <div className="flex items-center gap-3">
              Filters
              <span className="inline-flex items-center rounded-full bg-purple-600 px-1.5 py-0.5 text-xs font-medium text-white">
                {filtersCount}
              </span>
            </div>
          </HeaderButton>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="w-fill origin-top-center absolute left-0 z-10 mt-2 rounded-md bg-white px-8 py-6 text-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="flex flex-col gap-4">
              <div className="flex gap-16">
                <fieldset>
                  <legend className="text-base font-semibold leading-6 text-gray-900">
                    Source
                  </legend>
                  <div className="min-w-[160px]">
                    {sources.map((source, idx) => (
                      <div key={idx} className="relative flex items-start gap-2 py-2">
                        <div className="flex h-6 items-center">
                          <input
                            id={`source-${source.id}`}
                            name={`source-${source.id}`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={() => handleSetFitler('source', source.id)}
                            checked={filters.source.indexOf(source.id) >= 0}
                          />
                        </div>
                        <div className="w-max flex-1 text-sm leading-6">
                          <label
                            htmlFor={`source-${source.id}`}
                            className="select-none font-medium text-gray-900"
                          >
                            {source.name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </fieldset>

                <fieldset>
                  <legend className="text-base font-semibold leading-6 text-gray-900">
                    Priority
                  </legend>
                  <div className="min-w-[160px]">
                    {priorities.map((priority, idx) => (
                      <div key={idx} className="relative flex items-start gap-2 py-2">
                        <div className="flex h-6 items-center">
                          <input
                            id={`person-${priority.id}`}
                            name={`person-${priority.id}`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={() => handleSetFitler('priority', priority.id)}
                            checked={filters.priority.indexOf(priority.id) >= 0}
                          />
                        </div>
                        <div className="w-max flex-1 text-sm leading-6">
                          <label
                            htmlFor={`person-${priority.id}`}
                            className="select-none font-medium text-gray-900"
                          >
                            {priority.name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="flex justify-between">
                <Button onClick={handleClearFilters}>Clear</Button>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
