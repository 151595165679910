import { Button } from './Button'

export function LayoutMenu({
  layout,
  setLayout,
}: {
  layout: string
  setLayout: (layout: string) => void
}) {
  return (
    <span className="isolate inline-flex rounded-md text-sm shadow-sm">
      <Button
        className="rounded-none rounded-l-md"
        active={layout === 'grid'}
        onClick={() => setLayout('grid')}
      >
        Grid
      </Button>
      <Button
        className="-mx-px rounded-none rounded-r-md"
        active={layout === 'table'}
        onClick={() => setLayout('table')}
      >
        Table
      </Button>
    </span>
  )
}
