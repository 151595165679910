import { Button } from './Button'

export function SortOrder({
  ascending,
  setAscending,
}: {
  ascending: boolean
  setAscending: (ascending: boolean) => void
}) {
  return (
    <span className="isolate inline-flex rounded-md text-sm shadow-sm">
      <Button
        className="rounded-none rounded-l-md"
        active={ascending}
        onClick={() => setAscending(true)}
      >
        Asc
      </Button>
      <Button
        className="-mx-px rounded-none rounded-r-md"
        active={!ascending}
        onClick={() => setAscending(false)}
      >
        Desc
      </Button>
    </span>
  )
}
