import { HTMLAttributes } from 'react'

export function Conversations({ children }: HTMLAttributes<HTMLElement>) {
  return (
    <div className="flex flex-col gap-1">
      <span className="text-xs text-gray-400">Feed</span>
      {children}
    </div>
  )
}
