export const peopleQuery = `
id,
created_at,
first_name,
last_name,
email,
linkedin_url,
priority,
source,
conversations (
  id,
  created_at,
  from_us,
  content,
  topic
)`
