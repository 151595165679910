import { HTMLAttributes } from 'react'

export function HeaderStyle({ children }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="min-h-[50px]">
      <div className="fixed z-50 flex w-full items-center gap-8 border-b border-gray-300 bg-gray-900 px-4 py-2 text-sm text-gray-100">
        {children}
      </div>
    </div>
  )
}
