import { SyntheticEvent, useState } from 'react'

export function Auth() {
  const [key, setKey] = useState<string>('')

  const handleKeyChange = (e: React.FormEvent<HTMLInputElement>) => {
    setKey(e.currentTarget.value)
  }

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()

    try {
      localStorage.setItem('supabaseKey', key)
      window.location.reload()
    } catch (err) {
      // TODO
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="key"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                SupabaseKey
              </label>
              <div className="mt-2">
                <input
                  id="key"
                  name="key"
                  required
                  onChange={handleKeyChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
