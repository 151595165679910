import { useState } from 'react'

import { Conversation } from './Conversation'
import { Conversations } from './Conversations'
import { supabase } from './database'
import { LinkedIn } from './LinkedIn'
import { Priority } from './Priority'
import { PersonType } from './types'

export function Person(props: PersonType) {
  const [person, setPerson] = useState(props)

  const {
    id,
    first_name: firstName,
    last_name: lastName,
    email,
    linkedin_url: linkedinUrl,
    priority = 1,
    conversations,
  } = person

  const name = firstName || lastName ? `${firstName || ''} ${lastName || ''}` : email

  function handleUpdatePriority(id: number, value: number) {
    updatePriority(id, value)
  }

  async function updatePriority(id: number, priority: number) {
    const { error } = await supabase
      .from('people')
      .update({ priority: priority })
      .eq('id', id)
    if (error) {
      // TODO
    } else {
      setPerson({ ...person, priority: priority })
    }
  }

  return (
    <div className="flex w-full flex-col gap-3 rounded-lg border border-gray-200 bg-white p-2 text-sm text-gray-900">
      <div className="flex gap-2">
        <img
          className="inline-block h-14 w-14 rounded-md"
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
        <div className="flex w-full flex-col">
          <div className="flex items-start justify-between">
            <span className="text-ellipsis text-base font-semibold">{name}</span>
            <span className="text-xs text-gray-400">{id}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="elipsis text-xs text-gray-400">{email}</span>
            <div className="flex">
              {linkedinUrl && <LinkedIn url={linkedinUrl} width={20} height={20} />}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Priority
          id={id}
          priority={priority}
          handleUpdatePriority={handleUpdatePriority}
        />
        <span>Status</span>
      </div>
      <Conversations>
        {conversations.map((conversation) => (
          <Conversation key={conversation.id} {...conversation} />
        ))}
      </Conversations>
    </div>
  )
}
