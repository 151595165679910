import { twMerge } from 'tailwind-merge'

export function Priority(props: {
  id: number
  priority: number | null
  handleUpdatePriority: (id: number, priority: number) => void
}) {
  const priority = props.priority || 1

  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      {[1, 2, 3, 4, 5].map((step) => (
        <button
          key={step}
          type="button"
          className={twMerge(
            'relative inline-flex items-center bg-white px-1.5 py-0.5 text-xs text-gray-900',
            'border-y border-l ring-gray-300',
            'hover:opacity-80 focus:z-10',
            step === 1 && 'rounded-l-md',
            step === 5 && 'rounded-r-md border-r',
            priority === 1 && priority >= step && '&& bg-green-700 text-green-50',
            priority === 2 && priority >= step && '&& bg-lime-700 text-lime-50',
            priority === 3 && priority >= step && '&& bg-yellow-700 text-yellow-50',
            priority === 4 && priority >= step && '&& bg-orange-700 text-orange-50',
            priority === 5 && priority >= step && '&& bg-red-700 text-red-50',
          )}
          onClick={() => props.handleUpdatePriority(props.id, step)}
        >
          {step}
        </button>
      ))}
    </span>
  )
}
