import { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export function Button({ children, className, ...props }: HTMLAttributes<HTMLElement>) {
  return (
    <button
      type="button"
      className={twMerge(
        'self-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
        className,
      )}
      {...props}
    >
      {children}
    </button>
  )
}
