import { useCallback, useEffect, useState } from 'react'

import { Button } from './Button'
import { supabase } from './database'
import { AddUser } from './header/AddUser'
import { FilterMenu } from './header/FilterMenu'
import { HeaderStyle } from './header/HeaderStyle'
import { LayoutMenu } from './header/LayoutMenu'
import { Search } from './header/Search'
import { Sort } from './header/SortMenu'
import { SortOrder } from './header/SortOrder'
import { FilterType } from './header/types'
import { LayoutStyle } from './LayoutStyle'
import { Person } from './Person'
import { peopleQuery } from './query'
import { PeopleType } from './types'

const defaultFilters = {
  source: [],
  priority: [],
}

export function People() {
  const [loading, setLoading] = useState<boolean>(false)
  const [page, setPage] = useState(1)
  const [people, setPeople] = useState<PeopleType>([])
  const [layout, setLayout] = useState('grid')
  const [filters, setFilters] = useState<FilterType>(defaultFilters)
  const [sorting, setSorting] = useState<string>('id')
  const [ascending, setAscending] = useState<boolean>(true)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const getPeopleQuery = useCallback(
    (page: number) => {
      let query = supabase
        .from('people')
        .select(peopleQuery)
        .order(sorting, { ascending: ascending })
        .order('created_at', {
          foreignTable: 'conversations',
          ascending: false,
        })
        .range(20 * page, 20 * page + 19)

      if (filters.source.length !== 0) {
        query = query.in('source', filters.source)
      }
      if (filters.priority.length !== 0) {
        query = query.in('priority', filters.priority)
      }
      if (searchQuery !== '') {
        query = query.or(
          `first_name.ilike.${searchQuery}%,last_name.ilike.${searchQuery}%,email.ilike.${searchQuery}%`,
        )
      }

      return query
    },
    [filters, sorting, ascending, searchQuery],
  )

  useEffect(() => {
    async function getPeople() {
      setLoading(true)
      const { data, error } = await getPeopleQuery(0)
      if (error) {
        setLoading(false)
      } else {
        setPeople(data)
        setLoading(false)
      }
    }
    getPeople()
  }, [getPeopleQuery, filters])

  const handleLoadMore = () => {
    async function getPeople() {
      setLoading(true)
      const { data, error } = await getPeopleQuery(page)
      if (error) {
        setLoading(false)
      } else {
        setPeople([...people, ...data])
        setPage(page + 1)
        setLoading(false)
      }
    }
    getPeople()
  }

  const handleSetFitler = (key: 'source' | 'priority', value: string) => {
    const newFilters = { ...filters }
    const index = filters[key].indexOf(value)
    if (index >= 0) {
      newFilters[key].splice(index, 1)
    } else {
      newFilters[key].push(value)
    }
    setFilters(newFilters)
  }

  const handleClearFilters = () => {
    setFilters({...defaultFilters})
  }

  return (
    <div className="flex w-full min-h-full flex-col bg-gray-100 pb-10">
      <HeaderStyle>
        <span>CRM</span>
        <AddUser />
        <LayoutMenu layout={layout} setLayout={setLayout} />
        <FilterMenu
          filters={filters}
          handleSetFitler={handleSetFitler}
          handleClearFilters={handleClearFilters}
        />
        <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <Sort sorting={sorting} setSorting={setSorting} />
        <SortOrder ascending={ascending} setAscending={setAscending} />
      </HeaderStyle>
      <LayoutStyle>
        {people.map((person) => (
          <Person key={person.id} {...person} />
        ))}
      </LayoutStyle>
      {loading && (
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="self-center"
          fill="#9333ea"
        >
          <path d="M2,12A10.94,10.94,0,0,1,5,4.65c-.21-.19-.42-.36-.62-.55h0A11,11,0,0,0,12,23c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z">
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="0.6s"
              values="0 12 12;360 12 12"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      )}
      {!loading && <Button onClick={handleLoadMore}>Load More</Button>}
    </div>
  )
}
