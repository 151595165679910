import { twMerge } from 'tailwind-merge'

import { ConversationType } from './types'

export function Conversation({ content, topic, from_us: fromUs }: ConversationType) {
  return (
    <div
      className={twMerge(
        'w-[75%] rounded bg-gray-100 px-1.5  py-0.5 text-xs',
        fromUs ? 'self-end' : 'self-start',
      )}
    >
      <span className="font-semibold text-blue-700">{topic}: </span>
      <span>{content}</span>
    </div>
  )
}
